<template>
  <v-container class="bl_errorPage pa-0 ma-0">
    <v-tabs grow 
    height="70px"
    center-active color="grey" slider-color="primary" class="pt-2 bl_tabs">
      <v-tab v-for="tab of tabs" :key="tab.id" :to="tab.route" exact class="button text-subtitle-2 font-weight-bold primary--text"
      :class="{ 'active-class' : tab.id=='3'}"  style="flex-direction: column; align-items:center; justify-content: center; font-size:1rem !important; border-bottom: #efefef solid 3px;" disabled>
        {{ tab.name }}<br />
        <span v-if="tab.name=='建て方選択' && selectedHouseholdType" class="bl_tabs_tab_selected">{{selectedHouseholdType}}</span>
        <span v-if="tab.name=='報告方法選択' && selectedReportType" class="bl_tabs_tab_selected">{{selectedReportType}}</span>
        <span v-if="tab.name=='ファイルアップロード' && selectedReportType&& fileCnt !== 0" class="bl_tabs_tab_selected">合計 {{ fileCnt }} ファイル</span>
        <div v-if="tab.id=='3'" class="uq_badgeError">エラー一覧</div>
      </v-tab>
    </v-tabs>
    
    <v-container class="px-8">
      <v-row class="pt-8 pb-0 px-4">
        <v-col cols=12 class="pa-0">
          <p
            class="text-h5 el_centered_h5" 
          >エラーファイル一覧</p>
        </v-col>
      </v-row>
      <v-row class="px-3 pb-2 mt-4" style="display:flex; align-items: flex-start; justify-content:stretch;">
        <v-col cols=12 md=3 class="pa-0" style="height:100%; align-self: stretch;">
          <v-card
            outlined
            width="100%"
            height="100%"
            class="d-flex fill-height flex-column align-center text-body-2 px-4 py-5"
            >
            <small class="text--primary">エラーファイル </small>
            <div>合計<span class="text-h6 pl-2">{{ errorCnt }} </span>ファイル</div>
          </v-card>
        </v-col>
          
        <v-col cols="12" md="9" class="pl-4 pr-0 py-0">
          <div class="d-flex align-end justify-center mb-0">
            <p v-if="errorCnt == 0" class="mb-4">
            エラーファイルはありません。「戻る」ボタンをクリックしてファイルチェック結果をご確認ください。
            </p>
            <p v-else class="mb-2">
            エラーファイルを確認してください。「差し替え」ボタンからPDFファイルのプレビューが表示されます。PDFファイルの差し替え又は削除をお願いします。
            </p>
          </div>
          <div class="pl-6 pr-0 pb-2 uq_sm_flex_row" style="display: flex; flex-direction: column; align-items: flex-end; justify-content:flex-end;">
              <v-spacer></v-spacer>
              <v-btn
                @click="downloadErrorLists"
                :disabled="errorCnt == 0"
                width="250"
                height="36"
                color="#5E539A"
              >
              <span class="text-subtitle-2 font-weight-bold white--text">一覧ダウンロード</span>
              </v-btn>
          </div>
        </v-col>
      </v-row>
      <show-message/>

      <v-row class="mt-0" style="min-height: calc(100vh - 100px - 5rem);">
        <v-col cols="12" align="start">
          <v-data-table
            fixed-header
            style="border:1px solid #5E539A;"
            class="bl_errorListTable"
            :items="errorResults"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-header
            hide-default-footer
            @page-count="pageCount = $event">
            <template v-slot:header>
              <thead class="text-left bg_pale">
                <tr class="primary--text">
                  <td class="text-no-wrap">No.</td>
                  <td class="min-width:20em;">
                      <span class="text-no-wrap">PDFファイル名</span><br />
                      <small>住宅タイプの名称</small>
                  </td>
                  <td style="min-width:5.5em;">住宅の建て方</td>
                  <td class="text-no-wrap" style="min-width:2.5em;">戸数</td>
                  <td class="text-no-wrap" style="min-width:190px;">理由</td>
                  <td class="text-no-wrap" style="width:190px;">操作</td>
                </tr>
              </thead>
            </template>
            <template v-slot:body="{ items }">
              <tbody class="text-left">
                <tr
                  v-for="(result, index) in items"
                  :key="index"
                >
                  <td> {{ (itemsPerPage * (page - 1) ) + index + 1 }}</td>
                  <td>{{ result.originalName }}<br />
                    <small style="opacity: .85;">{{ result.household_name ? result.household_name : '-' }}</small>
                  </td>
                  <td>{{ result.household_type ? result.household_type : '-' }}</td>
                  <td>{{ result.household_num ? result.household_num : '-' }}</td>
                  <td><p class="d-flex ma-0" style="width:100%;">{{ result.error_msg }}</p></td>
                  <td class="d-flex align-center justify-space-between pr-4" style="max-width:300px;">
                    <v-btn
                      elevation="0"
                      @click="toFileDetail(index)"
                      class="font-weight-bold mr-2">
                      差し替え
                      <v-icon
                        right>
                        mdi-swap-horizontal
                      </v-icon>
                    </v-btn>
                    <v-btn
                      elevation="0"
                      @click="deleteErrorFile(index)"
                      class="font-weight-bold primary white--text"
                    >
                      削除
                      <v-icon
                        right>
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
          <v-pagination
            v-model="page"
            :length="pageCount"
            total-visible=7>
          </v-pagination>
        </v-col>
      </v-row>
      <v-row class="mx-0 mt-10 bl_fixedFooter">
        <v-btn
        @click="back"
        plain
        height="60"
        class="font-weight-bold text-h6 pl-0">
          <v-icon large>mdi-chevron-left</v-icon>
          戻る
        </v-btn>
      </v-row>
    </v-container>

    <div
      class="progress"
      v-show="progress">
      <v-progress-circular
          indeterminate
          :size="150"
          :width="15"
          color="grey"
          class="circular"
      ></v-progress-circular>
    </div>

    <!-- エラーファイル削除を確認するダイアログボックス-->
    <show-dialog
      v-if="deleteDialog && selectedIndex > -1"
      :initialShowDialog=deleteDialog
      :dialogMsg="errorResults[selectedIndex].originalName + 'を削除しますか。'"
      @confirmDialog=deleteFileConfirm
      @closeDialog="deleteDialog = false">
    </show-dialog>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import ShowMessage from '../components/ShowMessage.vue'
import ShowDialog from '../components/ShowDialog.vue'
import { tabs, getTimestamp } from '@/util'

export default {
  components: { ShowMessage, ShowDialog},

  data() {
    return {
      // ファイル取得の進行中表示の有無
      progress: false,
      // エラーファイル削除を確認するダイアログボックス表示の有無
      deleteDialog: false,
      //エラー確認ページ用のナビゲーション
      tabs: tabs,
      // ページネーション
      itemsPerPage: 500,
      page: 1,
      pageCount: 0,
    }
  },

  computed: {
    ...mapGetters([
      'isError',
      'selectedHouseholdType',
      'selectedReportType',
      'fileCnt',
      'checkedResult',
      'errorResults',
      'errorCnt',
      'selectedPdfSrc',
      'selectedIndex'
      ])
  },

  methods: {
    ...mapActions([
      'clearMessage',
      'getPdf',
      'update',
      'deleteFile'
      ]),

    // ファイル詳細画面へ遷移
    async toFileDetail(index){
      this.progress = true

      this.update({selectedIndex:index})
      await this.getPdf(index)

      if(this.selectedPdfSrc) {
        this.$router.push('/file-detail')
      }

      this.progress = false
    },

    // エラーファイル削除を確認するダイアログボックスを表示する
    async deleteErrorFile(index) {
      this.update({selectedIndex:index})
      this.deleteDialog = true
    },

    // エラーファイルを削除する
    async deleteFileConfirm() {
      await this.deleteFile()
      this.deleteDialog = false
    },

    // エラーファイル一覧のダウンロード
      downloadErrorLists() {
        // ファイル名
        const timestamp = getTimestamp()
        const filename = 'エラーファイル一覧_' + this.selectedHouseholdType + '_' + this.selectedReportType + '_' + timestamp + '.csv'

        //CSVデータ
        let csvData = 'PDFファイル名,住宅タイプの名称,住宅の建て方,戸数,理由\n'
        this.errorResults.forEach(function callback(file) {
            csvData += 
                file.originalName + "," +
                file.household_name + "," +
                file.household_type + "," +
                file.household_num + "," +
                file.error_msg + "\n"
        })
        //BOMを付与する（Excelでの文字化け対策）
        const bom = new Uint8Array([0xef, 0xbb, 0xbf])
        //Blobでデータを作成する
        const blob = new Blob([bom, csvData], { type: "text/csv" })

        //IE10/11用(download属性が機能しないためmsSaveBlobを使用）
        if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(blob, filename)
        //その他ブラウザ
        } else {
            //BlobからオブジェクトURLを作成する
            const url = (window.URL || window.webkitURL)
            const objectUrl = url.createObjectURL(blob)
            //ダウンロード用にリンクを作成する
            const download = document.createElement("a")
            //リンク先に上記で生成したURLを指定する
            download.href = objectUrl
            //download属性にファイル名を指定する
            download.download = filename
            //作成したリンクをクリックしてダウンロードを実行する
            download.click()
            //createObjectURLで作成したオブジェクトURLを開放する
            url.revokeObjectURL(objectUrl)
          }
      },


    // ファイルチェック画面へ遷移
    back() {
      this.clearMessage()
      this.$router.push('/file-check')
    }
  }
}
</script>

<style scoped>
.bl_errorListTable td{
    line-height: 1;
}
</style>